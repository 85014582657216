@import "scss/global.scss";

.menuBottom {
    padding: 8px 15px;

    li {
        margin-bottom: 8px;

        a {
            align-items: center;
            display: flex;

            color: #000;
            text-decoration: none;

            svg {
                margin-right: 10px;
            }

            &.active {
                color: $color-primary;
                font-weight: 500;
            }
        }
    }
}

.wrapLastPaymentPopver {
    height: 80vh;
}
