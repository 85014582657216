@import "scss/global.scss";

.layout {
    background: $color-gray-light;
    display: flex;

    min-height: 100vh;
    width: 100%;

    .navWrap {
        width: 70px;
        position: fixed;
        height: 100%;
        transition: all ease .3s;
        z-index: 2;
        
        &.open__menu {
            width: 200px;
        }

        .navWrap__icon {
            display: flex;
            
            background: #fff;
            border: 2px solid rgb(155, 155, 155);
            border-radius: 50%;
            color: rgb(155, 155, 155);
            cursor: pointer;
            height: 19px;
            width: 19px;
            z-index: 100;

            position: absolute;
            right: -10px;
            top: 50px;

            &.icon-close {
                right: -10px;
            }
        }
    }

    &__main {
        height: 100%;
        padding: 20px;
        padding-left: 90px;
        position: relative;
        transition: all ease .3s;
        width: 100%;

        &.shrink-main {
            padding-left: 215px;
        }

        &-avatar {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 2;
        }
    }

}

@media screen and (max-width: 768px) {
    .layout {
        .navWrap {
            display: none;
        }

        &__main {
            padding: 35px 15px;

            &.shrink-main {
                padding-left: 15px;
            }
        }
    }    
}

@media screen and (max-width: 480px) {
    .layout {

        &__main {
            margin-left: 0px;
            margin-bottom: 100px;
            padding-top: 60px;
        }
    }

    .avatarWrap {
        display: none;
    }
}
