@import "scss/global.scss";

.wrapLoader {
	text-align: center;

	svg {
		color: $color-primary;
	}
}

.navWrap {
    background: #fff;
    padding-top: 25px;
    position: relative;
    transition: all .3s ease;

    .navWrap__logo {
        text-align: center;
        width: 100%;

        img {
            width: 75%;
        }
    }

    .navWrap__menu {
        list-style: none;
        margin-top: 30px;
        padding-left: 25px;

        .navWrap__menu-item {
            display: flex;
            margin-bottom: 25px;
            
            a {
                display: flex;
               
                position: relative;
                text-decoration: none;
                width: 100%;

                svg {
                    color: #727272;
                    font-size: 20px;
                    margin-right: 25px;
                }

                span {
                    color: #727272;
                    font-family: "Open Sans";
                    font-size: 13px;
                    left: 40px;
                    position: absolute;
                    visibility: visible;
                }

                .hidden-links {
                    visibility: hidden;
                }
            }

            .active {
                border-right: 3px solid $color-primary;

                svg,
                span {
                    color: $color-primary;
                }
            }
        }
    }
}

.dropdownBranch {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  
  .activeSubmenu {
    color: $color-primary;
  }
  
  
  &__face {
      cursor: pointer;
      width: 100%;

    }

    &__text {
      color: #727272;
      position: relative;

      .icon-angle {
        color: #727272;
        margin: 0;
        position: absolute;
        top: 2px;
        right: 3px;
        transition: all ease .2s;
      }
      
      svg {
        font-size: 20px;
        margin-right: 15px;
      }
      
      span {
        font-size: 13px;
      }
    }

    &__items {
      height: 0;
      margin-left: 20px;
      overflow: hidden;
      transition: all ease .2s;

      li {
        margin-top: 15px;

        a {
          display: flex;
               
          position: relative;
          text-decoration: none;
          width: 100%;
          
          svg {
            color: #727272;
            font-size: 20px;
            margin-right: 20px;
        }
        
        span {
          color: #727272;
          font-family: "Open Sans";
          font-size: 13px;
        }
      }
      .activeZones {
          border-right: 3px solid $color-primary;

          svg,
          span {
              color: $color-primary;
          }
      }
    }
    
  }
  
  input {
      display: none;
      
      &:checked ~ .dropdownBranch__items {
        height: auto;
      }
      
      &:checked ~ .dropdownBranch__face > .dropdownBranch__text > .icon-angle {
        transform: rotate(90deg);
        transition: all ease .2s;
        
      }
    }
}

.dropdownSells {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  
  .activeSubmenu {
    color: $color-primary;
  }
  
  
  &__face {
      cursor: pointer;
      width: 100%;

    }

    &__text {
      color: #727272;
      position: relative;

      .icon-angle {
        color: #727272;
        margin: 0;
        position: absolute;
        top: 2px;
        right: 3px;
        transition: all ease .2s;
      }
      
      svg {
        font-size: 20px;
        margin-right: 15px;
      }
      
      span {
        font-size: 13px;
      }
    }

    &__items {
      height: 0;
      margin-left: 20px;
      overflow: hidden;
      transition: all ease .2s;

      li {
        margin-top: 15px;

        a {
          display: flex;
               
          position: relative;
          text-decoration: none;
          width: 100%;
          
          svg {
            color: #727272;
            font-size: 20px;
            margin-right: 20px;
        }
        
        span {
          color: #727272;
          font-family: "Open Sans";
          font-size: 13px;
        }
      }
      .activeSells {
          border-right: 3px solid $color-primary;

          svg,
          span {
              color: $color-primary;
          }
      }
    }
    
  }
  
  input {
      display: none;
      
      &:checked ~ .dropdownSells__items {
        height: auto;
      }
      
      &:checked ~ .dropdownSells__face > .dropdownSells__text > .icon-angle {
        transform: rotate(90deg);
        transition: all ease .2s;
        
      }
    }
}

.dropdownPeriods {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  
  .activeSubmenu {
    color: $color-primary;
  }
  
  
  &__face {
      cursor: pointer;
      width: 100%;

    }

    &__text {
      color: #727272;
      position: relative;

      .icon-angle {
        color: #727272;
        margin: 0;
        position: absolute;
        top: 2px;
        right: 3px;
        transition: all ease .2s;
      }
      
      svg {
        font-size: 20px;
        margin-right: 15px;
      }
      
      span {
        font-size: 13px;
      }
    }

    &__items {
      height: 0;
      margin-left: 20px;
      overflow: hidden;
      transition: all ease .2s;

      li {
        margin-top: 14px;

        a {
          align-items: center;
          display: flex;
               
          position: relative;
          text-decoration: none;
          width: 100%;
          
          svg {
            color: #727272;
            font-size: 20px;
            margin-right: 20px;
        }
        
        span {
          color: #727272;
          font-family: "Open Sans";
          font-size: 13px;
        }
      }
      .activeSells {
          border-right: 3px solid $color-primary;

          svg,
          span {
              color: $color-primary;
          }
      }
    }
    
  }
  
  input {
      display: none;
      
      &:checked ~ .dropdownPeriods__items {
        height: 76px;
      }
      
      &:checked ~ .dropdownPeriods__face > .dropdownPeriods__text > .icon-angle {
        transform: rotate(90deg);
        transition: all ease .2s;
        
      }
    }
}
  
  