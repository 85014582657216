.fadeToRight {
    position: relative;
    // top: 0.5rem;
    animation-name: moveToRight;
    animation-duration: 1s;
}

@keyframes moveToRight {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
