@import "scss/global.scss";

.barBottom {
    align-items: center;
    display: flex;
    justify-content: space-around;

    background: #000;
    border-radius: 15px 15px 0 0;
    height: 60px;
    width: 100%;

    position: fixed;
    bottom: 0;
    left: 0;

    z-index: 3;

    li {
        cursor: pointer;

        &:nth-child(3) {
            align-items: center;
            display: flex;
            justify-content: center;

            background: #000;
            border: 7px solid #fff;
            border-radius: 50%;
            height: 80px;
            margin-top: -60px;
            width: 80px;
        }

        svg {
            color: $color-primary;
            font-size: 30px;
        }
    }
}

@media screen and (min-width: 480px) {
    .barBottom {
        display: none;
    }
}