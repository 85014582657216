@import "scss/global.scss";
@import "../../../include-media.scss";
.payments_last {
    margin-bottom: 10px;
    padding: 15px 20px 5px 15px !important;
    overflow: hidden;

    ul {

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            align-items: center;
            display: flex;
            justify-content: space-between;

            font-size: 14px;
            padding: 0 5px;

            &:last-child {
                margin-bottom: 25px;
            }

            .list {
                align-items: center;
                display: flex;
                margin: 0;
                margin-top: 25px;
                
                figure {
                    margin: 0;
                    img {
                        padding: 7px 7px 7px 7px;
                        width: 40px;
                        
                    }
                }
                .text_pading_list{
                    padding-left: 30px;
                }
                .list__name,
                .list__date {
                    font-weight: 600;
                }

                .list__date {
                    font-size: 12px;
                }
            }

            .amount-total {
                font-weight: 600;
            }
        }
    }
}

@include media("<=tablet") {
    .payments_last {
      padding: 15px 40px 25px 30px !important;
    }
    .imaaa {
        width: 30px !important;
        border-radius: 8px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
                  
   }
}

@include media(">=phone") {
    .payments_last {
      padding: 15px 45px 35px 60px !important;
    }
    .imaaa {
        width: 30px !important;
        border-radius: 8px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
                  
   }

}
@include media(">=smallTablet") {
    .payments_last {
      padding: 15px 30px 5px 20px !important;
    }
    
    .imaaa {
        width: 32px !important;
        padding-right: 0px;
        border-radius: 8px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
                  
   } 
   
   @include media("<=phone") {

    .imaaa {
        width: 20px !important;
        border-radius: 8px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
   }

}
  
}