@import "scss/global.scss";

.avatarDesktop {
    align-items: center;
    display: flex;
    justify-content: center;

    background: $color-primary;
    border-radius: 50%;
    height: 37px;
    width: 37px;

    &:hover {
        cursor: pointer;
    }

    &__label {
        color: #fff;
    }
}

.avatarMobile {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;

    background: #44DE89;
    border-radius: 50%;
    height: 30px;
    padding: 0;
    width: 30px;

    &:hover {
        cursor: pointer;
    }

    &__label {
        color: #fff;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        margin-top: 3px;
    }
}

.avatar__menu {
    padding: 10px;
    width: 200px;

    .avatar__menu-body {
        padding: 0 8px;

        &-name {
            font-size: 18px;
            font-weight: 700;
        }
        
        &-email {
            color: rgb(131, 131, 131);
            font-size: 10px;
        }

        ul {
            padding: 8px 0;

            li {
                font-weight: 500;
                cursor: pointer;
            }
        }
    }

    .avatar__menu-logout {
        align-items: center;
        display: flex;

        border-top: 1px solid rgb(126, 126, 126);
        font-weight: 500;
        padding: 0 8px;
        padding-top: 4px;

        &:hover {
            cursor: pointer;
        }

        & svg {
            margin-right: 5px;
        }
    }
}


@media screen and (max-width: 480px) {
    .avatarDesktop {
        display: none;
    }

    .avatarMobile {
        display: block;
    }
}