.container-unauthorizedpage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f8f8f8;
    font-family: Arial, sans-serif;

    div {
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        width: 100%;
        text-align: center;

        h1 {
            font-size: 24px;
            color: red;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}