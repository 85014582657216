@import "scss/global.scss";

.customDrawer {
    visibility: hidden;
}

.btn-drawer {
    background-color: #f5f5fa;
    border-radius: 0 4px 4px 0;
    padding-left: 7px;
    position: fixed;
    top: 5px;
    left: 0;
    z-index: 999;
}

.draweWrap__logo {
    margin-top: 30px;
    text-align: center;
    width: 100%;

    img {
        width: 75%;
    }
}

.draweWrap__menu {
    list-style: none;
    margin-top: 30px;
    padding-left: 25px;

    li {
        display: flex;
        margin-bottom: 25px;
        
        a {
            display: flex;
            
            position: relative;
            text-decoration: none;
            width: 100%;

            svg {
                color: #b5b5b5;
                font-size: 20px;
                margin-right: 25px;
            }

            span {
                color: #b5b5b5;
                font-family: "Open Sans";
                font-size: 13px;
                left: 40px;
                position: absolute;
                visibility: visible;
            }
        }

        .active {
            border-right: 3px solid $color-primary;

            svg,
            span {
                color: $color-primary;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .customDrawer {
        visibility: visible;
    }
}

@media screen and (max-width: 480px) {
    .customDrawer {
        visibility: hidden;
    }
}