body {
    font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

select,
textarea,
input {
    border: none;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: #F2F2F2;
        cursor: not-allowed;
    }
}

textarea {
    resize: none;
}

button {
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.optional-label {
    background: #6c757d;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 4px 1px 4px;
}

.react-responsive-modal-modal {
    overflow-y: initial;
}

.rbc-month-view {
    height: 100vh;
}

.active-status {
   color: #479A5E; 
   font-weight: 500;
   text-transform: capitalize;
}

.inactive-status {
    color: #e83f36; 
    font-weight: 500;
    text-transform: capitalize;
}

.status {
    display: inline-block;

    border-radius: 4px;
    text-align: center;
    width: 90px;
}

.white {
    background: #fff;
}

.reembolsado {
    background: #F5DA63;
}

.pospuesto {
    background: #F5DA63;
}

.cancelado {
    background: #DA6662;
}

.por-pagar {
    background: #F0DF97;
}

.otro {
    background: #F0DF97;
}

// Used

/*
.danger-pay,
.info-pay,
.success-pay,
.Recurrente,
.oncePay,
.sent,
.rejected,
.expired,
.paid {
    border-radius: 4px;
    color: #fff;
    padding: 0px 6px;
}

.info-pay,
.sent {
    background: #71BCE7;
}

.danger-pay,
.rejected {
    background: #D64D41;
}

.expired {
    background: #606060;
}

.success-pay,
.paid {
    background: #5D9764;
}

.Recurrente {
    background: #96785B;
}

.oncePay {
    background: #97D0DE;
}
*/
