// Primary colours
$color-primary: #61CC82;
$color-blue-dark: #051231;
$color-gray-light: #f5f5fa;

// Secondary colours
$color-yellow: #F5DA63;
$color-brown: #947E5D;
$color-blue-light: #71BDE8;
$color-dark: #1D1D1B;
$color-gray: #828282;
$color-gray-bold: #b5b5b5;
$color-red: #e83f36;
