.marquee-container {
    position: fixed;
    top: 0;
    z-index: 1;

    button {
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1rem;
        line-height: 2;
        border-bottom: 2px solid #8BD9A1;
        background-color: #F5F5FA;

        &:hover {
            span {
                animation-play-state: paused;
            }
        }

        span {
            animation: marquee 3s infinite normal linear;
            padding: 0 0.5em;
        }

        @keyframes marquee {
            to {
                transform: translateX(-100%);
            }
        }
    }
}