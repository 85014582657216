.miniCalendar {
    border: 1px solid #44DE89;
    border-radius: 2px;
    height: 23px;
    width: 25px;
    margin-top: 2.5px;
    position: relative;
    
    &__header {
        border: 1px solid #44DE89;
        border-radius: 2px 2px 0 0 ;
        height: 6px;
        width: 25px;

        position: absolute;
        top: -1px;
        left: -1px;
        right: -9px;

        &-orificeL {
            background: #44DE89;
            border-radius: 50%;
            height: 3px;
            width: 3px;
            position: inherit;
            top: 0.5px;
            left: 4px;

            z-index: 2;
        }

        &-orificeR {
            background: #44DE89;
            border-radius: 50%;
            height: 3px;
            width: 3px;
            position: inherit;
            top: 0.5px;
            right: 4px;

            z-index: 2;
        }

        &-orificeL::before,
        &-orificeR::before {
            content: "";
            background-color: #44DE89;
            border-radius: 3px;
            height: 4px;
            width: .1px;
            position: inherit;
            top: -2.5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__body {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        
        color: #44DE89;
        font-size: 11px;
        font-weight: 600;
        height: 100%;
    }
}