@import "scss/global.scss";

.loader__wrap {
    align-items: center;
    display: flex;
    justify-content: center;

    .spinner {
        border: 3px solid rgba(255, 255, 255, .8);
        border-radius: 50%;
        border-left-color: $color-primary;

        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}